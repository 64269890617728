var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{class:[_vm.inputStyle === 'secondary' ? _vm.primaryInput.inputWrapper : _vm.secondaryInput.inputWrapper]},[(_vm.label && _vm.shouldShowHelp)?_c('span',{staticClass:"flex text-base",class:[!!_vm.error ? 'text-journey-orange font-bold' : 'text-journey-black font-bold', { required: _vm.required }],style:({
			width: _vm.inputStyle === 'primary' ? _vm.labelWidth : '100%',
		})},[_vm._v(" "+_vm._s(_vm.label)+" "),_c('div',{staticClass:"flex items-center mx-0.5"},[_c('img',{staticClass:"w-4",attrs:{"src":require('../../assets/icons/help-icon.svg'),"alt":""},on:{"mouseover":function($event){_vm.displayHelp = true},"mouseleave":function($event){_vm.displayHelp = false}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayHelp),expression:"displayHelp"}],staticClass:"\n\t\t\t\t\tabsolute\n\t\t\t\t\tw-10/12\n\t\t\t\t\tz-50\n\t\t\t\t\ttransform\n\t\t\t\t\ttranslate-x-1/2\n\t\t\t\t\ttop-10\n\t\t\t\t\tright-1/2\n\t\t\t\t\tbg-journey-dark-gray\n\t\t\t\t\ttext-journey-white\n\t\t\t\t\tshadow\n\t\t\t\t\tp-1\n\t\t\t\t\trounded-lg\n\t\t\t\t\tflex\n\t\t\t\t\tjustify-between\n\t\t\t\t"},[_vm._v(" "+_vm._s(_vm.helpText)+" ")])])]):(_vm.label)?_c('span',{staticClass:"text-base",class:[!!_vm.error ? 'text-journey-orange font-bold' : 'text-journey-black font-bold', { required: _vm.required }],style:({
			width: _vm.inputStyle === 'primary' ? _vm.labelWidth : '100%',
		})},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.type === 'password')?_c('div',{staticClass:"w-full",class:['flex flex-col', _vm.inputStyle === 'secondary' ? _vm.primaryInput.inputWidth : _vm.secondaryInput.inputWidth]},[_c('input',{staticClass:"bg-journey-white border-1 border-journey-dark-gray rounded-lg py-1 px-1 w-full",class:[
				_vm.inputStyle === 'secondary' ? _vm.primaryInput.inputStyle : _vm.secondaryInput.inputStyle,
				{ 'border-0': _vm.disabled } ],attrs:{"id":_vm.id,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"type":_vm.passwordFieldType,"min":_vm.min,"max":_vm.max,"maxlength":_vm.maxLength,"minlength":_vm.minLength},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"keyup":[function($event){return _vm.$emit('emitBlur', $event.target.value)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onHandleEnterSubmit.apply(null, arguments)}]}}),(_vm.value)?_c('span',{staticClass:"self-end cursor-pointer"},[_c('img',{staticClass:"w-5 mr-2 z-10 -mt-8 opacity-75",attrs:{"src":require(("../../assets/icons/" + _vm.passwordIcon))},on:{"click":_vm.changePasswordVisibility}})]):_vm._e()]):(_vm.type === 'chat')?_c('div',{class:[
			'flex flex-col',
			_vm.inputStyle === 'secondary' ? _vm.primaryInput.inputWidth && _vm.primaryInput.inputStyle : _vm.secondaryInput.inputWidth,
			{ 'border-0': _vm.disabled },
			_vm.inputClass ]},[_c('textarea',{staticClass:"bg-journey-white border-1 border-journey-dark-gray rounded-lg py-1 px-1 resize-none disable-scroll h-12",class:[{ 'border-0': _vm.disabled }],attrs:{"id":_vm.id,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"type":_vm.type,"min":_vm.min,"max":_vm.max,"maxlength":_vm.maxLength,"minlength":_vm.minLength},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"keyup":[function($event){return _vm.$emit('emitBlur', $event.target.value)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onHandleEnterSubmit.apply(null, arguments)}],"paste":function($event){return _vm.$emit('pasteImage', $event)}}})]):_c('div',{class:[
			'flex flex-col',
			_vm.inputStyle === 'secondary' ? _vm.primaryInput.inputWidth && _vm.primaryInput.inputStyle : _vm.secondaryInput.inputWidth,
			{ 'border-0': _vm.disabled },
			_vm.inputClass ]},[(_vm.inputMask)?_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.inputMask),expression:"inputMask"}],staticClass:"bg-journey-white border-1 border-journey-dark-gray rounded-lg py-1 px-1",class:[{ 'border-0': _vm.disabled }],attrs:{"id":_vm.id,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"type":_vm.type,"min":_vm.min,"max":_vm.max,"maxlength":_vm.maxLength,"minlength":_vm.minLength},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value, $event)},"keyup":[function($event){return _vm.$emit('emitBlur', $event.target.value)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onHandleEnterSubmit.apply(null, arguments)}]}}):_c('input',{staticClass:"bg-journey-white border-1 border-journey-dark-gray rounded-lg py-1 px-1",class:[{ 'border-0': _vm.disabled }],attrs:{"id":_vm.id,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"type":_vm.type,"min":_vm.min,"max":_vm.max,"maxlength":_vm.maxLength,"minlength":_vm.minLength},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"keyup":[function($event){return _vm.$emit('emitBlur', $event.target.value)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onHandleEnterSubmit.apply(null, arguments)}]}}),(!!_vm.error && _vm.inputStyle === 'primary')?_c('span',{staticClass:"self-start mt-1 text-journey-orange text-xs"},[_vm._v(_vm._s(_vm.error))]):_vm._e()]),(!!_vm.error && _vm.inputStyle === 'secondary')?_c('span',{staticClass:"self-start mt-1 text-journey-orange text-xs"},[_vm._v(_vm._s(_vm.error))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }