<template>
	<div class="auth-wrapper auth-v1 gap-x-10 lg:gap-x-24 flex-wrap gap-y-5 py-10 md:py-0">
		<password-recovery-modal :isOpen="isOpen" :onClosed="toggleModal"></password-recovery-modal>
		<div class="w-full md:w-6/12 lg:w-4/12 px-10 flex flex-col justify-center">
			<div class="mb-16 mx-auto self-start">
				<img src="../assets/icons/journey-green-logo.svg" alt="Logo Journey" />
			</div>

			<custom-input
				v-for="(header, index) in headers"
				:key="index"
				:label="header.name"
				v-model="loginData[header.key]"
				:type="header.type"
				:placeholder="header.placeholder"
				:error="header.error"
				inputStyle="secondary"
				class="mb-2"
				:handleEnterSubmit="header.enterSubmit"
			></custom-input>

			<custom-button variant="quintenary" class="self-end mt-0.5" :onClick="toggleModal"
				>Esqueceu sua senha?</custom-button
			>

			<custom-button :disabled="loading" :onClick="signIn" :loading="loading" class="py-1 w-full mt-2"
				>Entrar</custom-button
			>
		</div>
		<div
			class="
				bg-journey-green
				w-9/12
				md:w-4/12
				lg:w-4/12
				h-80vh
				3xl:h-50vh
				min-w-300
				rounded-2xl
				justify-center
				items-center
				logo-container
			"
		>
			<img src="../assets/icons/logo-journey-full.svg" alt="Logo Journey" />
		</div>
	</div>
</template>

<script>
	import { Component, Prop, Vue, Watch } from "vue-property-decorator";
	import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
	import { setErrorsOnForm } from "@/helpers/utils";
	import CustomInput from "@/layouts/components/Input.vue";
	import CustomButton from "@/layouts/components/Button.vue";
	import PasswordRecoveryModal from "@/layouts/components/PasswordRecoveryModal.vue";
	import { HomePageByRole, UserRole } from "@/interfaces/user";

	@Component({
		components: {
			"custom-button": CustomButton,
			"custom-input": CustomInput,
			"password-recovery-modal": PasswordRecoveryModal,
		},
	})
	export default class Login extends Vue {
		loading = false;
		isOpen = false;
		headers = [
			{ name: "Email", key: "email", placeholder: "Digite seu e-mail", error: "", enterSubmit: this.signIn },
			{
				name: "Senha",
				key: "password",
				placeholder: "Digite sua senha",
				type: "password",
				error: "",
				enterSubmit: this.signIn,
			},
		];

		loginData = {
			email: "",
			password: "",
			shouldPersistSession: false,
		};

		toggleModal() {
			this.isOpen = !this.isOpen;
		}

		async signIn() {
			this.loading = true;
			try {
				await this.$store.dispatch("auth/login", this.loginData);
				this.$router.push(HomePageByRole[this.$store.state.auth.activeUser?.role]);
			} catch (error) {
				if (error?.response?.properties) {
					setErrorsOnForm(this.headers, error.response.properties);
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: "Erro",
							text: error.message || "Erro ao enviar os dados, tente novamente",
							variant: "danger",
							icon: "AlertCircleIcon",
						},
					});
				}
			}
			this.loading = false;
		}
	}
</script>

<style lang="scss">
	@import "@core/scss/vue/pages/page-auth.scss";

	.logo-container {
		display: flex;
		@media screen and (max-width: 760px) {
			display: none;
		}
	}
</style>
