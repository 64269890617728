








































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { mask } from "vue-the-mask";
import { getMask, InputMask } from "@/helpers/utils";

@Component({
	directives: {
		mask,
	},
})
export default class CustomInput extends Vue {
	@Prop({ default: "" }) label!: string;
	@Prop() id?: string;
	@Prop({ default: "" }) placeholder!: string;
	@Prop({ default: "" }) error!: string;
	@Prop({ default: "" }) value!: string;
	@Prop({ default: "text" }) type!: string;
	@Prop({ default: false }) disabled!: boolean;
	@Prop({ default: (event: Event) => null }) onChange!: (inputKey: string) => void;
	@Prop({ default: (event: Event) => null }) handleEnterSubmit!: (inputKey: string) => void;
	@Prop({ default: "" }) inputKey!: string;
	@Prop({ default: false }) required!: boolean;
	@Prop({ default: false }) shouldShowHelp: boolean;
	@Prop({ default: "" }) helpText: string;
	@Prop({ default: "" }) min!: string;
	@Prop({ default: "" }) max!: string;
	@Prop({ default: "" }) mask!: InputMask;
	@Prop({ default: "" }) maxLength!: string;
	@Prop({ default: "" }) minLength!: string;
	@Prop({ default: "primary" }) inputStyle!: string;
	@Prop({ default: "112px" }) labelWidth!: string;
	@Prop({ default: "min-w-320 w-full" }) inputClass!: string;

	passwordFieldType = "password";
	passwordIcon = "opened-black-eye.svg";

	displayHelp = false;

	get inputMask() {
		return getMask(this.mask);
	}

	primaryInput = { inputWrapper: "flex flex-col items-stretch", inputStyle: "mt-1.5", inputWidth: "w-full" };
	secondaryInput = {
		inputWrapper: "flex flex-row justify-between items-center gap-x-5",
		inputWidth: this.inputClass,
	};
	changePasswordVisibility() {
		if (this.passwordFieldType === "password") {
			this.passwordFieldType = "text";
			this.passwordIcon = "closed-black-eye.svg";
		} else {
			this.passwordFieldType = "password";
			this.passwordIcon = "opened-black-eye.svg";
		}
	}

	mounted() {
		this.valueChange();
	}

	@Watch("value")
	valueChange() {
		if (this.onChange && typeof this.onChange === "function") {
			this.onChange(this.inputKey);
		}
	}

	handleValue() {
		return this.value;
	}

	onHandleEnterSubmit(event: any) {
		this.handleEnterSubmit?.(event);
	}
}
