




































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { hasMissingRequiredKeys, returnFormToDefaultValue } from "@/helpers/index";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { requestPasswordReset, resetPassword } from "@/api/_auth";
import { showSuccessAlert } from "@/helpers";
import { setErrorsOnForm } from "@/helpers/utils";
import BaseModal from "./BaseModal.vue";
import CustomButton from "./Button.vue";
import CustomInput from "./Input.vue";

@Component({
	components: {
		"base-modal": BaseModal,
		"custom-button": CustomButton,
		"custom-input": CustomInput,
	},
})
export default class PasswordRecoveryModal extends Vue {
	@Prop({ default: false }) isOpen!: boolean;
	@Prop({ default: () => null }) onClosed!: () => void;

	loading = false;

	requestChangeForm = {
		kind: "requestChange",
		title: "Esqueceu sua senha?",
		description: "Insira seu e-mail abaixo para receber um código de verificação",
		recoveryData: {
			email: "",
		},
		headers: [{ name: "Email", key: "email", required: true, placeholder: "usuario@empresa.com.br", error: "" }],
	};

	changePasswordForm = {
		kind: "changePassword",
		title: "Gerar nova senha?",
		description: "Escolha uma nova senha",
		minWidth: "min-w-500",
		recoveryData: {
			passwordResetCode: "",
			password: "",
		},
		headers: [
			{
				name: "Código recebido por e-mail",
				key: "passwordResetCode",
				required: true,
				placeholder: "Digite o código",
				error: "",
			},
			{
				name: "Senha",
				key: "password",
				type: "password",
				required: true,
				placeholder: "Digite sua nova senha",
				error: "",
			},
		],
	};

	currentForm: {
		kind?: string;
		title?: string;
		description?: string;
		minWidth?: string;
		recoveryData?: { email?: string; passwordResetCode?: string | number; password?: string };
		headers: any[];
	} = this.requestChangeForm;

	async requestPasswordUpdate() {
		this.loading = true;
		try {
			await requestPasswordReset({ email: this.requestChangeForm.recoveryData.email });

			this.currentForm = this.changePasswordForm;
		} catch (error) {
			if (error?.response?.properties) {
				setErrorsOnForm(this.requestChangeForm.headers, error.response.properties);
			} else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: "Erro",
						text: error.message || "Erro ao enviar os dados, tente novamente",
						variant: "danger",
						icon: "AlertCircleIcon",
					},
				});
			}
		}
		this.loading = false;
	}

	async updatePassword() {
		this.loading = true;
		try {
			await resetPassword({
				passwordResetCode: this.changePasswordForm.recoveryData.passwordResetCode,
				password: this.changePasswordForm.recoveryData.password,
			});
			showSuccessAlert("Senha alterada com sucesso");
		} catch (error) {
			if (error?.response?.properties) {
				setErrorsOnForm(this.changePasswordForm.headers, error.response.properties);
			} else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: "Erro",
						text: error.message || "Erro ao enviar os dados, tente novamente",
						variant: "danger",
						icon: "AlertCircleIcon",
					},
				});
			}
		}
		this.loading = false;
	}

	buttonClicked() {
		if (this.currentForm.kind === "requestChange") {
			this.requestPasswordUpdate();
		} else {
			this.updatePassword();
		}
	}

	goToPasswordChange() {
		this.currentForm = this.changePasswordForm;
	}

	get buttonDisabled() {
		return hasMissingRequiredKeys(this.currentForm.headers, this.currentForm.recoveryData);
	}

	@Watch("isOpen")
	isOpenChange() {
		if (this.isOpen) {
			returnFormToDefaultValue(this.requestChangeForm.headers, this.requestChangeForm.recoveryData);
			returnFormToDefaultValue(this.changePasswordForm.headers, this.changePasswordForm.recoveryData);
			this.currentForm = this.requestChangeForm;
		}
	}
}
